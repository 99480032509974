@use 'styles/abstracts' as *;

.initial {
  color: $col-gray-100;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  b {
    font-size: large;
  }
}

.small {
  width: 1.5rem;
  height: 1.5rem;
  b {
    font-size: small;
  }
}

.f {
  background-color: $col-gray-700;
}

.l {
  background-color: $col-gray-500;
}
