@use '../abstracts/' as *;

div.MuiSelect-select {
  font-family: $montserrat;
}

// Select

li.MuiMenuItem-root {
  font-family: $montserrat;
}

div.MuiSelect-select {
  padding: $size-0 $size-1;
  color: $col-primary-600;
  font-weight: 600;
  &:focus {
    background-color: transparent;
  }
  &#selectHandlerSelect {
    font-weight: 400;
    color: $col-gray-100;
  }
}

div.MuiPaper-root.MuiTableContainer-root {
  box-shadow: none;
  overflow: hidden !important;
}

div.MuiPaper-root.MuiMenu-paper {
  background-color: $col-gray-100;
}

div.MuiInputBase-root {
  background-color: transparent;
  &.Mui-focused {
    background-color: transparent;
  }
  &:hover {
    background-color: transparent;
  }
}

div.MuiFormControl-root {
  width: 100%;
}

li.Mui-selected {
  color: $col-primary-600;
  font-weight: 600;
}

.MuiSelect-nativeInput {
  color: $col-primary-600;
  font-weight: 600;
}

// Tables

th.MuiTableCell-root,
td.MuiTableCell-root {
  font-family: $montserrat;
  font-size: 1rem !important;
  padding: $size-0;
  color: $col-gray-700;
  min-width: min-content;
  @include breakpoint(phone) {
    width: 30px;
  }
}

tr.MuiTableRow-root {
  position: relative;
  width: 40.625rem;
  @include breakpoint(phone) {
    width: 22.875rem;
  }
}

th.MuiTableCell-head {
  border-bottom: none;
}

thead.MuiTableHead-root th {
  font-weight: 600;
  color: $col-gray-600;
}

// DataGrid

div.MuiDataGrid-root {
  border: none;
  font-family: $montserrat !important;
  font-size: 1rem !important;
}

div.MuiDataGrid-root .MuiDataGrid-withBorderColor {
  border-color: transparent !important;
}

div.MuiDataGrid-row {
  min-height: 3.7rem !important;
  @include breakpoint(phone) {
    height: 1rem !important;
  }
}

.MuiDataGrid-iconSeparator {
  color: transparent !important;
}

div.MuiDataGrid-columnHeaderTitle {
  font-weight: 600;
  color: $col-gray-600;
  @include breakpoint(phone) {
    font-size: 15px !important;
  }
}

div.MuiDataGrid-columnHeader:focus {
  outline: none !important;
}

div.MuiDataGrid-columnHeader:hover,
div.MuiDataGrid-iconButtonContainer {
  width: inherit !important;
}

div.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none;
}
div.MuiDataGrid-cell {
  max-height: none !important;
}

div.MuiDataGrid-row {
  border-radius: $size-1;
  @include breakpoint(phone) {
    border-radius: inherit;
  }
}

div.MuiDataGrid-iconButtonContainer,
.MuiButtonBase-root,
.MuiIconButton-root {
  button:hover {
    background-color: transparent !important;
  }
}

div.MuiDataGrid-virtualScroller {
  overflow: hidden;
}
