@use 'styles/abstracts' as *;

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100%;
  background-color: rgba($col-gray-900, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  h5 {
    color: $col-gray-600;
  }
  position: relative;
  background-color: $col-gray-100;
  box-shadow: 5px 5px 10px rgba($col-gray-900, 0.2);
  border-radius: $size-1;
  @include breakpoint(phone) {
    max-width: 97%;
  }
  ::-webkit-scrollbar {
    position: fixed;
    bottom: 20px;
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: $col-gray-dark;
    border-radius: $size-2;
  }
  button {
    padding: $size-0;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: $size-1 $size-3;
  }
  .iconHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $size-1;
    .iconTitle {
      display: flex;
      align-items: center;
      .icon {
        padding-right: $size-1;
      }
    }
  }
  .scrollable {
    margin: $size-1 $size-3;
  }
  button {
    width: fit-content;
    display: flex;
    flex-direction: row;
  }
}

.notifyModal {
  position: relative;
  background-color: $col-gray-100;
  box-shadow: 5px 5px 10px rgba($col-gray-900, 0.2);
  border-radius: $size-1;
  button {
    padding: $size-1;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $size-1;
  }
  .scrollable {
    display: flex;
    flex-flow: column;
  }
  button {
    width: fit-content;
    display: flex;
    flex-direction: row;
  }
}
