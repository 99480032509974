@use 'styles/abstracts' as *;

.generalContainer {
  margin: $size-1 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  .matchCardContainer {
    margin-top: $size-3;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 1rem;
    @include breakpoint(phone) {
      row-gap: 0;
    }
    .group {
      margin: 0 $size-2;
    }
  }
}
