@use 'styles/abstracts' as *;

.editGroupMatchModal {
  min-width: 20rem;
  width: 26.625rem;
  padding: $size-2 $size-1;
  @include breakpoint(phone) {
    width: inherit;
  }
  .societyName {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
  .homeGoalsTransparent {
    input {
      background-color: transparent;
    }
  }
  .gray {
    input {
      background-color: $col-gray-200;
    }
  }
  .homeGoals {
    width: 100%;
    height: 4.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @include breakpoint(phone) {
      width: 100%;
    }
    .homeGoal {
      padding: $size-1 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 49%;
      input {
        font-size: $size-2;
        border: none;
        border-radius: $size-1;
        width: 3rem;
        height: 2rem;
        padding: 0 $size-0;
        text-align: center;
        color: $col-gray-900;
        font-weight: 600;
        &:focus {
          outline: none;
        }
        &::placeholder {
          color: $col-gray-900;
          font-weight: 600;
        }
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        display: none;
      }
      &[data-score='none'] {
        background-color: transparent;
      }
      &[data-score='x'] {
        background-color: $col-yellow;
      }
      &[data-score='w'] {
        background-color: $col-secondary-100;
      }
      &[data-score='l'] {
        background-color: $col-pink;
      }
      .teamLogo {
        background-color: $col-gray-400;
        border-radius: $size-1;
        width: 3.75rem;
        height: 3.75rem;
      }
    }

    .homeGoalRight {
      border-top-right-radius: $size-1;
      border-bottom-right-radius: $size-1;
    }
    .homeGoalLeft {
      border-top-left-radius: $size-1;
      border-bottom-left-radius: $size-1;
    }
  }
  .homeCalendar {
    width: min-content;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    input {
      background-color: transparent;
      color: $col-gray-900;
      font-weight: 600;
    }
  }
  .calendar {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding: $size-2 0 $size-3 0;
    input {
      background-color: $col-gray-200;
      color: $col-gray-600;
      width: 49%;
      border: none;
      border-radius: $size-1;
      height: 2rem;
      display: flex;
      flex-flow: row-reverse;
      &::placeholder {
        color: $col-gray-600 !important;
      }
      &:focus {
        outline: none;
      }
    }
    &:not(:last-child) {
      margin-right: $size-0;
    }
  }
  p {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .homeCal {
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      background-color: $col-alert-dark;
      width: 6.5rem !important;
      background-color: transparent !important;
      color: $col-gray-900;
      font-weight: 600;
    }
  }
  .buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      width: 49%;
    }
  }
}
