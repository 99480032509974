@use 'styles/abstracts' as *;

.matSelect {
  border-color: transparent;
  .select {
    background-color: $col-gray-100;
    color: $col-primary-600;
    border-radius: $size-1;
    height: $size-5;
    box-shadow: 5px 5px 10px rgba($col-gray-900, 0.2);
  }
  svg {
    color: $col-primary-600;
    position: absolute;
    right: $size-0;
  }
  p {
    margin-left: $size-1;
    font-size: $size-2;
  }
}
