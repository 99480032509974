@use 'styles/abstracts' as *;

.container {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: $size-1;
  gap: $size-1;
  .date {
    height: 1.313rem;
    @include breakpoint(phone) {
      height: 0.875rem;
    }
  }
  :first-child {
    border-top-left-radius: $size-1;
    border-bottom-left-radius: $size-1;
  }
  :last-child {
    border-top-right-radius: $size-1;
    border-bottom-right-radius: $size-1;
  }
  .card {
    cursor: pointer;
    display: flex;
    width: 12.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: $size-0 $size-0 $size-1 rgba($col-gray-900, 0.2);
    border-radius: $size-1;
    margin: $size-0 0;
    @include breakpoint(phone) {
      height: 3rem;
      width: 8.875rem;
      justify-content: space-around;
    }
    .break {
      background-color: $col-gray-400;
      width: 1px;
      height: 3.75rem;
      @include breakpoint(phone) {
        height: 3rem;
      }
    }
    .teamContainer {
      width: calc(50% - 0.5px);
      height: 3.75rem;
      display: flex;
      align-items: center;
      justify-content: space-around;
      &:first-child {
        padding-left: $size-1;
      }
      &:last-child {
        padding-right: $size-1;
      }
      @include breakpoint(phone) {
        height: 3rem;
      }
      &[data-score='none'] {
        background-color: transparent;
      }
      &[data-score='x'] {
        background-color: $col-yellow;
      }
      &[data-score='w'] {
        background-color: $col-secondary-100;
      }
      &[data-score='l'] {
        background-color: $col-pink;
      }
      .teamLogo {
        background-color: $col-gray-400;
        border-radius: $size-1;
        width: 3rem;
        height: 3rem;
        @include breakpoint(phone) {
          width: 2.25rem;
          height: 2.25rem;
        }
      }
    }
  }
}
