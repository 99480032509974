@use 'styles/abstracts' as *;

::-webkit-scrollbar {
  width: $size-1;
}
::-webkit-scrollbar-thumb {
  background: $col-gray-600;
  border-radius: $size-2;
}

.container {
  height: calc(100dvh - 11.25rem);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  @include breakpoint(tab-land) {
    .group {
      margin-top: $size-1;
    }
  }
  .scroll {
    overflow: auto;
    height: calc(100dvh - 11.25rem);
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    @include breakpoint(phone) {
      height: calc(100dvh - 8.5rem);
    }

    .top {
      margin-bottom: 2rem;
    }
  }
  .group {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
  }
  .save {
    display: flex;
    align-self: center;
    width: 40rem;
    height: 3rem !important;
    margin-bottom: $size-1;
    button {
      height: 3rem !important;
    }
    @include breakpoint(phone) {
      width: 22.875rem;
      button {
        width: 22.875rem;
        height: 3rem !important;
      }
    }
  }
}
