@use 'styles/abstracts' as *;

.startedCompetition {
  padding: $size-0;
  background-color: $col-gray-100;
  border-radius: $size-1;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  margin-bottom: $size-2;
  @include breakpoint(phone) {
    width: 90%;
  }
  .message {
    color: $col-gray-700;
    display: flex;
    flex-flow: column;
  }
  svg {
    margin: 0 $size-2;
  }
}

.container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  height: calc(100dvh - 7.5rem);
  width: 40rem;
  .createSociety {
    padding: 0 $size-2;
    width: 100%;
    overflow: auto;
    .startedCompetition {
      margin-top: 1rem;
      @include breakpoint(phone) {
        width: 100%;
      }
    }
    .scroll {
      display: flex;
      align-items: center;
    }
  }
  @include breakpoint(phone) {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
  }
}
.footer {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  .save {
    padding-bottom: $size-1;
    display: flex;
    align-content: center;
    justify-content: center;
    width: 100%;
    button {
      height: 3rem !important;
    }
    @include breakpoint(phone) {
      width: 22.875rem;
    }
  }
}
.confirmModal {
  width: 25rem !important;
  position: relative;
  .text {
    width: 90%;
    @include breakpoint(phone) {
      width: 80%;
    }
  }
  .actions {
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .button {
      margin: $size-3 $size-0;
      button {
        :not(:last-of-type) {
          margin-right: $size-1;
        }
      }
    }
    @include breakpoint(phone) {
      width: 80%;
    }
  }
}
