@use 'styles/abstracts' as *;

.container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  height: calc(100dvh - 11.25rem);
  width: 100%;
  overflow: auto;
  .groupCards {
    margin-top: 5.5rem;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-around;
    gap: 0.5rem;
    width: 80%;
    @include breakpoint(tab-land) {
      margin-top: $size-1;
      flex-flow: column;
    }
    .cardGroup {
      display: flex;
      align-items: center;
      justify-content: center;
      .vector {
        margin-top: 1.5rem;
        display: flex;
        align-items: center;
        @include breakpoint(tab-land) {
          margin-top: $size-5;
        }
        @include breakpoint(phone) {
          margin-top: 0;
        }
      }
      .cards {
        height: 18.75rem;
        display: flex;
        flex-flow: column;
        align-items: center;
        .rankingTransparent {
          height: 1.25rem;
        }
        .matchCard {
          @include breakpoint(tab-land) {
            margin-right: 0;
            margin-left: 0;
          }
        }
        .top {
          margin-bottom: 2.5rem;
          display: flex;
          flex-flow: column;
          align-items: center;
          @include breakpoint(tab-land) {
            margin-bottom: 3rem;
          }
          @include breakpoint(phone) {
            margin-bottom: 3.5rem;
          }
        }
      }
    }
  }
}
