@use 'styles/abstracts' as *;

.noDisplay {
  display: none;
}

.containerPhoto {
  span {
    color: $col-primary-600;
  }
  display: flex;
  button {
    margin-left: 1rem;
  }
}
