@use 'styles/abstracts' as *;

.container {
  margin: $size-1 0 $size-1 0;
  .main {
    width: 100%;
    margin-bottom: 1rem;
  }
  .wrapper {
    align-self: center;
  }
}
