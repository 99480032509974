@use 'styles/abstracts' as *;

.rankingContainer {
  display: flex;
  box-shadow: $size-0 $size-0 $size-1 rgba($col-gray-900, 0.2);
  width: 25.063rem;
  height: 3.75rem;
  border-radius: $size-1;
  align-items: center;
  justify-content: space-between;
  .podium {
    svg {
      margin: 0 $size-1;
    }
  }
  .gray {
    width: 5.625rem;
    background-color: $col-gray-200;
    display: flex;
    align-items: center;
  }
  .ranking {
    width: 5.625rem;
    height: 3.75rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .empty {
      height: 3rem;
      width: 3rem;
      background-color: $col-gray-400;
      border-radius: $size-1;
    }
    .teamLogo {
      background-color: $col-gray-400;
      border-radius: $size-1;
      width: 3rem;
      height: 3rem;
      margin: 0 $size-1;
      @include breakpoint(phone) {
        width: 2.25rem;
        height: 2.25rem;
      }
    }
  }
}

@include breakpoint(phone) {
  .rankingContainer {
    display: flex;
    box-shadow: $size-0 $size-0 $size-1 rgba($col-gray-900, 0.2);
    width: 20.313rem;
    height: 3rem;
    border-radius: $size-1;
    align-items: center;
    justify-content: space-between;
    .podium {
      svg {
        margin: 0 $size-1;
      }
    }
    .gray {
      width: 5.625rem;
      background-color: $col-gray-200;
      display: flex;
      align-items: center;
    }
    .ranking {
      width: 5.625rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .empty {
        margin-left: $size-1;
        height: 2.25rem;
        width: 2.25rem;
        background-color: $col-gray-400;
        border-radius: $size-1;
      }
    }
  }
}
