@use 'styles/abstracts' as *;

.text {
  color: $col-gray-700;
  font-weight: 300;
  font-size: 14px;
  width: 260px;
}

.share {
  max-width: 330px;
}

.notice {
  position: absolute;
  top: 65%;
  right: 43%;
}

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: $size-1 0;
  gap: $size-0;
  .link {
    width: calc(100% - 56px);
    height: 48px;
    flex-grow: 1;
    background-color: $col-gray-200;
    color: $col-gray-900 !important;
    border-radius: $size-1;
    display: flex;
    align-items: center;
    @include breakpoint(phone) {
      height: 40px;
    }
    a {
      color: $col-gray-900;
      text-decoration: none;
      padding: $size-1;
      align-self: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      display: block;
    }
  }
}
