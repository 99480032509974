@use 'styles/abstracts' as *;

.confirmModal {
  width: 25rem !important;
  position: relative;
  .text {
    width: 90%;
    @include breakpoint(phone) {
      width: 80%;
    }
  }
  .actions {
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .button {
      margin: $size-3 $size-0;
      button {
        :not(:last-of-type) {
          margin-right: $size-1;
        }
      }
    }
    @include breakpoint(phone) {
      width: 80%;
    }
  }
}

.notice {
  position: absolute;
  bottom: 10%;
  right: 50%;
  @include breakpoint(phone) {
    position: absolute;
    width: 50%;
    bottom: 10%;
    left: 25%;
  }
}
