@use 'src/styles/abstracts' as *;

.backdrop {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  .notice {
    width: max-content;
    margin: $size-1;
    display: flex;
    align-self: center;
    justify-self: center;
    align-items: center;
    justify-content: space-between;
    background-color: $col-gray-100;
    padding: $size-1;
    border-radius: $size-1;
    pointer-events: auto;
    box-shadow: $size-0 $size-0 $size-1 rgba($col-gray-900, 0.2);
    .message {
      margin-left: $size-1;
      color: $col-gray-900;
    }
  }
}
