*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: -webkit-fill-available;
  height: 100dvh;
  width: 100%;
  overflow: hidden;
}
