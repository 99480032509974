@use 'styles/abstracts' as *;

.teamLogo {
  background-color: $col-gray-400;
  border-radius: $size-1;
  width: 3rem;
  height: 3rem;
  @include breakpoint(phone) {
    width: 2.25rem;
    height: 2.25rem;
  }
}

.container {
  display: flex;
  flex-flow: column;
  align-items: center;
  height: calc(100dvh - 7.5rem);
  width: 100%;
  .table {
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100dvh - 7.5rem);
    width: 40.625rem;
    @include breakpoint(tab-land) {
      width: 100%;
    }
    .alert {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $col-gray-200;
      padding: $size-1 $size-2;
      border-radius: $size-1;
      svg {
        margin-right: $size-2;
      }
      @include breakpoint(phone) {
        font-size: 12px;
      }
    }
    .ranking {
      b {
        color: $col-primary-600;
      }
    }
  }
}
