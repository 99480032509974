@use 'styles/abstracts' as *;

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100dvh - 7.5rem);
}

.shorterNavbar {
  height: calc(100dvh - 11.25rem);
}
