@use 'styles/abstracts' as *;

.societyPic {
  display: flex;
  border-radius: $size-1;
  background-color: $col-gray-600;
  align-items: center;
  justify-content: center;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: $size-1;
    &[alt='placeholder'] {
      width: 50%;
      height: 50%;
      border-radius: 0;
    }
  }
}

.large {
  width: 6.25rem;
  height: 6.25rem;
}

.medium {
  width: 3.75rem;
  height: 3.75rem;
}

.normal {
  width: 3rem;
  height: 3rem;
  @include breakpoint(phone) {
    width: 2.25rem;
    height: 2.25rem;
  }
}
