@use 'styles/abstracts' as *;

.form {
  padding-bottom: $size-2;
}

.fields {
  display: flex;
  flex-flow: column;
  width: 19.875rem;
}

.error {
  input {
    border: 1px solid $col-alert-pink !important;
  }
}

.secret {
  input {
    -webkit-text-security: disc;
    color: $col-gray-300;
  }
}

.fieldLogin {
  display: flex;
  flex-flow: column;
  position: relative;
  margin-bottom: $size-2;
  padding-right: $size-1;
  label {
    color: $col-gray-300;
    font-size: $size-2;
    margin-bottom: $size-1;
    margin-top: $size-1;
  }
  input {
    border: none;
    outline: none;
    background-color: rgba($col-gray-100, 0.2);
    border-radius: $size-1;
    font-size: $size-2;
    margin: $size-2 0 0 0;
    height: $size-4;
    padding: $size-0 $size-0 $size-0 $size-2;
    color: $col-gray-300;
    &::placeholder {
      color: $col-gray-300;
    }
  }
  .invalid {
    position: absolute;
    bottom: 0;
    right: $size-2;
    font-size: 12px;
    transform: translateY(100%);
    color: $col-alert-pink;
  }
  .select {
    position: relative;
    select {
      box-shadow: $size-0 $size-0 $size-1 rgba($col-gray-900, 0.2);
      border: none;
      font-size: $size-2;
      width: 100%;
      outline: none;
      border-radius: $size-1;
      padding: $size-1 $size-2;
      appearance: none;
      :checked {
        background-color: $col-gray-200;
        font-weight: 600;
      }
    }
    span {
      color: $col-gray-900 !important;
      position: absolute;
      right: $size-2;
      top: calc(50% + #{$size-0});
      transform: translateY(-50%);
    }
  }
}

.buttonsEdit {
  display: flex;
  justify-content: space-between;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: $size-2;
}

.field {
  display: flex;
  flex-flow: column;
  position: relative;
  margin-bottom: $size-2;
  label {
    color: $col-gray-300;
    font-size: $size-2;
    margin-bottom: $size-1;
    margin-top: $size-1;
  }
  input {
    border: none;
    outline: none;
    background-color: $col-gray-200;
    border-radius: $size-1;
    font-size: $size-2;
    margin: $size-2 0 0 0;
    height: $size-4;
    padding: $size-0 $size-0 $size-0 $size-2;
    &:disabled {
      color: $col-gray-300;
    }
  }
  .invalid {
    position: absolute;
    bottom: 0;
    right: $size-2;
    font-size: 12px;
    transform: translateY(100%);
    color: $col-alert-light;
  }
  .select {
    position: relative;
    select {
      box-shadow: $size-0 $size-0 $size-1 rgba($col-gray-900, 0.2);
      border: none;
      font-size: $size-2;
      width: 100%;
      outline: none;
      color: $col-gray-900;
      border-radius: $size-1;
      padding: $size-1 $size-2;
      appearance: none;
      :checked {
        background-color: $col-gray-200;
        font-weight: 600;
      }
    }
    span {
      color: $col-gray-900 !important;
      position: absolute;
      right: $size-2;
      top: calc(50% + #{$size-0});
      transform: translateY(-50%);
    }
  }
}

.buttonsEdit {
  display: flex;
  justify-content: space-between;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: $size-2;
}
