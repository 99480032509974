@use 'styles/abstracts' as *;

.generalContainer {
  background-color: $col-primary-900;
  .backofficeContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $col-gray-100;
    height: 3.75rem;
    border-bottom: 2px solid $col-gray-900;
    button {
      margin: 0.625rem $size-8;
      @include breakpoint(phone) {
        margin: 10px calc($size-0 + $size-1);
      }
    }
  }
  .colLight {
    background-color: $col-primary-700;
  }
  .colDark {
    background-color: $col-primary-900;
  }
  .visibleContainer {
    height: 3.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 9.438rem;
      @include breakpoint(phone) {
        width: 100%;
      }
    }
    @include breakpoint(phone) {
      padding: 0 $size-1;
    }
  }
  .toggleLight {
    background-color: $col-primary-700;
    &:hover {
      background-color: $col-primary-600;
    }
  }
  .toggle {
    background-color: $col-primary-800;
    &:hover {
      background-color: $col-primary-700;
    }
  }
  .logout {
    transform: rotate(180deg);
  }
}

.groupContainer {
  background-color: $col-gray-200;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 19.313rem;
    @include breakpoint(phone) {
      width: 100%;
    }
  }
  @include breakpoint(phone) {
    padding: 0 $size-1;
  }
}
