@use 'styles/abstracts' as *;

.ellipsis {
  @include truncate(300px);
  @include breakpoint(phone) {
    @include truncate(100px);
  }
  transition: all 0.2s linear;
  &:hover:after {
    content: attr(data-text);
    overflow: auto;
    max-height: 100px;
    background: $col-gray-100;
    max-width: 300px;
    position: absolute;
    padding: $size-1;
    z-index: 10;
    box-shadow: 0 2px $size-0 0 rgba($col-gray-900, 0.2);
    white-space: normal;
    display: flex;
    margin-top: -1.25rem;
  }
}

.buttons {
  width: 50px;
  button {
    margin-right: -$size-2;
  }
  :hover {
    background-color: transparent !important;
  }
}
