@use 'styles/abstracts' as *;

.wrapper {
  align-self: center;
}

.confirm {
  display: flex;
  flex-flow: column;
  span {
    padding: $size-1 0;
  }
}

.delete {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
