@use '../abstracts' as *;

button {
  cursor: pointer;
  border: none;
  outline: none;
  transition: all 0.3s ease;
  font-family: $montserrat;
  font-size: 18px;
  height: $size-6;
  overflow: hidden;
  @include breakpoint(phone) {
    height: $size-5;
  }
  &.shadow {
    box-shadow: $size-0 $size-0 $size-1 rgba($col-gray-900, 0.2);
  }
  &.dark {
    background-color: $col-gray-dark;
    color: $col-gray-100;
    &.active {
      color: $col-primary-600;
    }
  }
  &.light {
    background-color: $col-gray-100;
    color: $col-gray-900;
    &:hover {
      background-color: $col-gray-100;
    }
  }
  &.mediumLight {
    background-color: $col-gray-200;
    color: $col-gray-900;
  }
  &.primary {
    background-color: $col-primary-600;
    color: $col-gray-100;
    @include breakpoint(phone) {
      height: $size-5;
    }
  }
  &.secondary {
    background-color: $col-gray-300;
    color: $col-gray-900;
  }
  &.alert {
    background-color: $col-alert-light;
    color: $col-gray-100;
  }
  &.active {
    background-color: $col-gray-300;
    color: $col-primary-600;
  }
  &.transparent {
    background-color: transparent;
    color: $col-gray-900;
  }
  &.text {
    padding: $size-1 $size-2;
    border-radius: $size-1;
    display: flex;
    align-items: center;
    justify-content: center;
    @include breakpoint(phone) {
      height: $size-5;
    }
    svg {
      margin-right: $size-1;
    }
  }
  &.icon {
    width: $size-6;
    border-radius: $size-1;
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint(phone) {
      width: $size-5;
    }
  }
  &:hover {
    transition: all 0.3s ease;
    &.dark,
    &.primary,
    &.secondary {
      box-shadow: inset 0 0 0 10em rgba($col-gray-100, 0.3);
    }
    &.transparent,
    &.light {
      background-color: $col-gray-300;
    }
    &.mediumLight {
      box-shadow: none;
      background-color: $col-primary-600;
    }
    &:disabled {
      box-shadow: none;
      cursor: initial;
    }
  }
  &:disabled {
    background-color: $col-gray-200;
    color: $col-gray-500;
    box-shadow: none;
    opacity: 0.5;
    svg {
      filter: opacity(0.5);
    }
  }
  &.lightPrimary {
    background-color: $col-gray-100;
    color: $col-primary-600;
    &:disabled {
      background-color: $col-gray-200;
      color: $col-gray-500;
      box-shadow: none;
      opacity: 0.5;
      svg {
        filter: opacity(0.5);
        fill: $col-gray-500 !important;
      }
    }
  }
  &.customDisabled {
    background-color: transparent;
    color: $col-gray-100;
    &:disabled {
      background-color: transparent;
      color: $col-gray-100;
      opacity: 1;
    }
    svg {
      filter: opacity(1);
    }
  }
}
