@use 'styles/abstracts' as *;

.cropImageModal {
  display: flex;
  flex-direction: column;
}

.wrapper {
  width: 100%;
  align-self: center;
  .crop {
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin: $size-3 0 $size-4 0;
    max-height: max-content;
  }
  .buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: $size-1;
    }
  }

  .images {
    align-items: center;
    justify-content: center;
    margin-top: $size-1;
    display: flex;
    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22rem;
      height: 22rem;
      background-color: $col-gray-500;
      border-radius: $size-1;
      @include breakpoint(phone) {
        width: 19.875rem;
        height: 19.875rem;
      }
    }
    .cropper {
      width: fit-content;
      height: fit-content;
      max-height: 25rem;
      max-width: 25rem;
      @include breakpoint(phone) {
        max-width: 19.875rem;
        max-height: 19.875rem;
      }
    }
    img {
      object-fit: contain;
      max-height: 25rem;
      max-width: 25rem;
    }
    .canvas {
      display: none;
    }
  }

  .created {
    margin: auto;
    padding-right: $size-2;
    padding-bottom: $size-2;
  }
}
