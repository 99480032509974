@use 'styles/abstracts' as *;

.container {
  background-image: url('../../../public/backgrounddark_login.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  .formLog {
    border-radius: $size-1;
    width: max-content;
    padding: 1.5rem;
    background-color: rgba($col-white, 0.2);
    .error {
      display: flex;
      color: $col-alert-pink;
      align-items: center;
      justify-content: center;
    }
    .header {
      position: relative;
      padding-bottom: 3.188rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      .arrow {
        width: 3rem;
        position: absolute;
        left: 0;
      }
      h5 {
        color: $col-gray-100;
      }
    }
    button {
      width: 100%;
    }
  }
}
