@use 'styles/abstracts' as *;

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0.5rem 0;
  @include breakpoint(phone) {
    margin-bottom: 2rem;
  }
  b {
    .link {
      color: $col-primary-600;
    }
  }
  @include breakpoint(phone) {
    margin-top: $size-1;
  }
}
